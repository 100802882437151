<template>
  <div>
    <div class="z-0">
      <div
        class="mx-auto sm:max-w-xl md:max-w-full lg:max-w-screen-2xl md:px-24 lg:px-8 lg:py-10 pt-10 p-2"
      >
        <a
          href="/search"
          class="text-xl font-bold xl:text-xl text-transparent bg-clip-text bg-gradient-to-r from-green-400 via-purple-500 to-blue-500 transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer text-shadow-white"
        >
          Trending Prompts

          <b class="text-sm text-gray-200"> Explore All </b>
          >
        </a>
        <hr class="h-px my-4 bg-gray-200 border-0 dark:bg-gray-700" />

        <div class="hidden lg:block">
          <Carousel :items-to-show="5.5" :itemsToScroll="2">
            <Slide
              v-for="s in prompts"
              :key="s"
              class="transition duration-300"
            >
              <div
                class="p-0.5 bg-opacity-25 bg-gradient-to-r from-purple-500 via-pink-600 to-purple-300 rounded"
              >
                <div class="text-sm border-none rounded bg-gray-900">
                  <div>
                    <a :href="linkDetail(s)">
                      <img :src="s.img_1" class="w-56" />
                      <div class="flex">
                        <div class="flex-none">
                          <h4 class="text-xs p-2 mt-1 text-gray-200">
                            ⛵ {{ s.title.slice(0, 12) }} ...
                          </h4>
                        </div>

                        <div
                          class="flex-grow p-2 text-right text-gray-200 text-sm hidden sm:block"
                        >
                          {{ s.price }}
                        </div>
                      </div>
                    </a>
                  </div>
                </div>
              </div>
            </Slide>

            <template #addons>
              <navigation>
                <template #next>
                  <div class="bg-gray-700 hover:bg-gray-900 bg-opacity-50">
                    <i
                      class="fas fa-angle-right p-2"
                      style="font-size: 50px; color: white"
                    ></i>
                  </div>
                </template>
                <template #prev>
                  <div class="bg-gray-700 hover:bg-gray-900 bg-opacity-50">
                    <i
                      class="fas fa-angle-left p-2"
                      style="font-size: 50px; color: white"
                    ></i>
                  </div>
                </template>
              </navigation>
            </template>
          </Carousel>
        </div>

        <div class="lg:hidden md:block">
          <Carousel :items-to-show="2.5" class="lg:hidden md:block">
            <Slide
              v-for="s in prompts"
              :key="s"
              class="transition duration-300 ease-in-out transform hover:-translate-y-1 cursor-pointer"
            >
              <div
                class="p-0.5 bg-opacity-25 bg-gradient-to-r from-purple-500 via-pink-600 to-purple-300 rounded m-1"
              >
                <div class="text-sm border-none rounded bg-gray-900">
                  <a :href="linkDetail(s)">
                    <img :src="s.img_1" />

                    <div class="flex">
                      <div class="flex-none">
                        <h4 class="text-xs p-2 mt-1 text-gray-200">
                          ⛵ {{ s.title.slice(0, 12) }} ...
                        </h4>
                      </div>

                      <div
                        class="flex-grow p-2 text-right text-gray-200 text-sm hidden sm:block"
                      >
                        {{ s.price }}
                      </div>
                    </div>
                  </a>
                </div>
              </div>
            </Slide>
            <template #addons>
              <navigation>
                <template #next>
                  <div class="bg-gray-700 hover:bg-gray-900 bg-opacity-50">
                    <i
                      class="fas fa-angle-right p-2"
                      style="font-size: 30px; color: white"
                    ></i>
                  </div>
                </template>
                <template #prev>
                  <div class="bg-gray-700 hover:bg-gray-900 bg-opacity-50">
                    <i
                      class="fas fa-angle-left p-2"
                      style="font-size: 30px; color: white"
                    ></i>
                  </div>
                </template>
              </navigation>
            </template>
          </Carousel>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import ConnectWallet from "@/components/ConnectWallet.vue";
import StickFooter from "@/components/StickFooter.vue";
import FundSummary from "@/components/FundSummary.vue";
import Social from "@/components/Social.vue";
import LiveTx from "@/components/LiveTx.vue";
import Stats from "@/components/Stats.vue";
import OurTeam from "@/components/OurTeam.vue";
import Roadmap from "@/components/Roadmap.vue";
import Stake from "@/components/Stake.vue";

import { mapGetters, mapActions } from "vuex";

import NFTs from "@/api/NFTs";
import Prompt from "@/api/prompt";

import { POOL_ADDRESS, VERSION } from "../../../config";

import { Carousel, Pagination, Navigation, Slide } from "vue3-carousel";

export default {
  data() {
    return {
      version: VERSION,
      products: [],
      products_2: [],
      prompts: [],

      ref: this.$route.params.ref,
    };
  },
  components: {
    Social,
    LiveTx,
    Stats,
    OurTeam,
    Roadmap,
    Stake,
    ConnectWallet,
    StickFooter,
    FundSummary,
    Carousel,
    Slide,
    Pagination,
    Navigation,
  },
  methods: {
    fixed(a) {
      return (a.toFixed(0) / 1).toLocaleString();
    },
    digit(a, digi) {
      if (a) {
        return a.toLocaleString(undefined, {
          minimumFractionDigits: digi,
        });
      }
    },

    async trendPrompts() {
      await Prompt.trendPrompts()
        .then((res) => {
          this.prompts = res.data.prompts;
        })
        .catch((error) => {
          console.log(error);
        });
    },
    linkDetail(s) {
      if (s.price == "free") {
        return "/promptFree/" + s._id;
      } else {
        return "/prompt/" + s._id;
      }
    },
  },
  created() {
    this.trendPrompts();
    if (this.$route.params.ref) {
      localStorage.setItem("Ref", JSON.stringify(this.$route.params.ref));
    } else {
      localStorage.setItem("Ref", JSON.stringify(POOL_ADDRESS));
    }
  },
};
</script>
